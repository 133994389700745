<template>
  <div class="container mt-5">
    <h1 class="mb-4">Tìm kiếm & Lọc giao dịch</h1>
    <form @submit.prevent="searchTransactions">
      <div class="row mb-3">
        <!-- Tìm kiếm theo mô tả giao dịch -->
        <div class="col">
          <input
              type="text"
              v-model="filters.description"
              class="form-control"
              placeholder="Mô tả giao dịch"
          />
        </div>

        <!-- Lọc theo nguồn giao dịch -->
        <div class="col">
          <select v-model="filters.source" class="form-control">
            <option value="">Chọn nguồn</option>
            <option value="VietinBank">VietinBank</option>
            <option value="VCB">Vietcombank</option>
          </select>
        </div>
        <div class="col">
          <button type="submit" class="btn btn-primary">Tìm kiếm</button>
        </div>
      </div>
    </form>

    <!-- Bảng hiển thị kết quả -->
    <table class="table table-bordered">
      <thead>
      <tr>
        <th>Ngày GD</th>
        <th>Số tiền</th>
        <th>Mô tả giao dịch</th>
        <th>Ngân hàng</th>
      </tr>
      </thead>
      <tbody>
      <tr v-for="tx in transactions" :key="tx.date">
        <td>{{ tx.date }}</td>
        <td>{{ tx.credit }}</td>
        <td>{{ tx.description }}</td>
        <td>{{ tx.source }}</td>
      </tr>
      </tbody>
    </table>

    <!-- Pagination Controls -->
    <nav>
      <ul class="pagination">
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" @click.prevent="loadPage(1)">First</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === 1 }">
          <a class="page-link" href="#" @click.prevent="loadPage(currentPage - 1)">Prev</a>
        </li>
        <li class="page-item"
            v-for="page in paginationPages"
            :key="page"
            :class="{ active: currentPage === page }">
          <a class="page-link" href="#" @click.prevent="loadPage(page)">{{ page }}</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" @click.prevent="loadPage(currentPage + 1)">Next</a>
        </li>
        <li class="page-item" :class="{ disabled: currentPage === totalPages }">
          <a class="page-link" href="#" @click.prevent="loadPage(totalPages)">Last</a>
        </li>
      </ul>
    </nav>
  </div>
</template>

<script>
import axios from 'axios';  // Thêm dòng này để import axios

export default {
  data() {
    return {
      transactions: [], // Kết quả tìm kiếm
      filters: {
        description: '',
        source: '',
      },
      currentPage: 1,
      totalPages: 1,
      maxVisiblePages: 5 // Số trang hiển thị tối đa
    };
  },
  methods: {
    loadPage(page) {
      if (page < 1 || page > this.totalPages) return;
      this.currentPage = page;
      this.searchTransactions();
    },
    searchTransactions() {
      const params = new URLSearchParams(this.filters);
      params.append('page', this.currentPage);

      axios.get(`/search?${params.toString()}`)
          .then(response => {
            this.transactions = response.data.transactions;
            this.totalPages = response.data.totalPages; // Cập nhật tổng số trang
          })
          .catch(error => {
            console.error("Error fetching data", error);
          });
    }
  },
  computed: {
    paginationPages() {
      let pages = [];
      let startPage = Math.max(1, this.currentPage - Math.floor(this.maxVisiblePages / 2));
      let endPage = Math.min(this.totalPages, startPage + this.maxVisiblePages - 1);

      // Điều chỉnh nếu trang cuối bị giới hạn
      if (endPage - startPage < this.maxVisiblePages - 1) {
        startPage = Math.max(1, endPage - this.maxVisiblePages + 1);
      }

      for (let i = startPage; i <= endPage; i++) {
        pages.push(i);
      }
      return pages;
    }
  },
  mounted() {
    this.searchTransactions();
  }
};
</script>

<style>
.container {
  max-width: 900px;
}
</style>
